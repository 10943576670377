import { DocumentReference } from 'firebase/firestore';
import Subscription from './subscription';

class UserSubscription {
  uid: string;
  userId: string;
  subscriptionRef: DocumentReference<Subscription>;
  subscription: Subscription | undefined;

  constructor(
    uid: string,
    userId: string,
    subscriptionRef: DocumentReference<Subscription>,
    subscrition: Subscription | undefined
  ) {
    this.uid = uid;
    this.userId = userId;
    this.subscriptionRef = subscriptionRef;
    this.subscription = subscrition;
  }
}

export default UserSubscription;

class UserAuth {
  uid: string;
  username: string | null;
  userSubscriptionNames: string[];

  constructor(
    uid: string,
    username: string | null,
    userSubscriptionNames: string[]
  ) {
    this.uid = uid;
    this.username = username;
    this.userSubscriptionNames = userSubscriptionNames;
  }
}

export default UserAuth;

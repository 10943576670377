export const INVALID_PASSWORD = "auth/wrong-password";
export const EMAIL_EXISTS = "auth/email-already-in-use";
export const WEAK_PASSWORD = "auth/weak-password";
export const INVALID_EMAIL = "auth/invalid-email";
export const USER_NOT_FOUND = "auth/user-not-found";
export const INTERNAL_ERROR = "auth/internal-error";
export const REQUIRES_RECENT_LOGIN = "auth/requires-recent-login";

export const AUTH_ERROR_MESSAGE_MAP: any = {
    [INVALID_PASSWORD]: "Wrong password",
    [EMAIL_EXISTS]: "Email already in use",
    [WEAK_PASSWORD]: "Password should be at least 6 characters",
    [USER_NOT_FOUND]: "User not found",
    [INVALID_EMAIL]: "Please provide a valid email",
    [REQUIRES_RECENT_LOGIN]: "You need to be recently signed in to perform a sensitive operation, please login and try again",
    [INTERNAL_ERROR]: "Generic error"
}

export const USER_ERROR_MESSAGE_MAP: any = {
    [INTERNAL_ERROR]: "Generic error"
}

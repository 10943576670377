class User {
    uid: string;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    deactivatedAt: Date | null;

    constructor(uid: string, email: string | null, firstName: string | null, lastName: string | null, deactivatedAt: Date | null = null) {
        this.firstName = firstName || null;
        this.lastName = lastName || null;
        this.email = email || null;
        this.uid = uid;
        this.deactivatedAt = deactivatedAt;
    }
}

export default User;
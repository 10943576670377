import { getApp, FirebaseApp, initializeApp } from "firebase/app";
import { getAuth, Auth, connectAuthEmulator } from "firebase/auth";
import { Firestore, connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { isTest } from "../utils/helpers";

const getFirebaseApp: () => FirebaseApp = () => {
    console.log('[FirebaseProvider] get firebase client');

    let app = null;

    try {
        app = getApp();
    } catch(error) {
        app = initFirebaseApp();
    }

    return app;
}

const initFirebaseApp: () => FirebaseApp = () => {
    console.log('[FirebaseProvider] init firebase client');

    let app = null;

    const firebaseConfig = {
        apiKey: process.env.GATSBY_FIREBASE_API_KEY,
        authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
        storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
        appId: process.env.GATSBY_FIREBASE_APP_ID,
    }

    try {
        app = initializeApp(firebaseConfig);
    } catch(error) {
        console.error(error);
        throw new Error('[FirebaseProviderError] ' + JSON.stringify(error));
    }

    return app;
}

export const getFirebaseAuthService: () => Auth = () => {
    console.log('[FirebaseProvider] get auth service');

    const auth = getAuth(getFirebaseApp());

    try {
        if (isTest()) connectAuthEmulator(auth, "http://localhost:9099");
    } catch (error) {}
    
    return auth;
}

export const getFirestoreService: () => Firestore = () => {
    console.log('[FirebaseProvider] get firestore service');

    const firestore = getFirestore(getFirebaseApp());

    try {
        if (isTest()) connectFirestoreEmulator(firestore, "localhost", 8080);
    } catch (error) {}
    
    return firestore;
}
import './src/styles/global.scss';
import 'prismjs/plugins/command-line/prism-command-line.css';

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.hash) return;
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), 0);
  } else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];
    window.setTimeout(
      () => window.scrollTo(...savedPosition),
      0
    );
  }
  return false;
};

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"academy","short_name":"academy","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b06cdf12508cdafdd7cea79e6a2434a3"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":true,"maxWidth":1216,"quality":80,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"svgIcon":"<svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M12.9375 4.5H2.8125C2.66332 4.5 2.52024 4.55926 2.41475 4.66475C2.30926 4.77024 2.25 4.91332 2.25 5.0625V15.1875C2.25 15.3367 2.30926 15.4798 2.41475 15.5852C2.52024 15.6907 2.66332 15.75 2.8125 15.75H12.9375C13.0867 15.75 13.2298 15.6907 13.3352 15.5852C13.4407 15.4798 13.5 15.3367 13.5 15.1875V5.0625C13.5 4.91332 13.4407 4.77024 13.3352 4.66475C13.2298 4.55926 13.0867 4.5 12.9375 4.5ZM12.375 14.625H3.375V5.625H12.375V14.625ZM15.75 2.8125V12.9375C15.75 13.0867 15.6907 13.2298 15.5852 13.3352C15.4798 13.4407 15.3367 13.5 15.1875 13.5C15.0383 13.5 14.8952 13.4407 14.7898 13.3352C14.6843 13.2298 14.625 13.0867 14.625 12.9375V3.375H5.0625C4.91332 3.375 4.77024 3.31574 4.66475 3.21025C4.55926 3.10476 4.5 2.96168 4.5 2.8125C4.5 2.66332 4.55926 2.52024 4.66475 2.41475C4.77024 2.30926 4.91332 2.25 5.0625 2.25H15.1875C15.3367 2.25 15.4798 2.30926 15.5852 2.41475C15.6907 2.52024 15.75 2.66332 15.75 2.8125Z\" fill=\"white\"/></svg>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8ZL9WRLJW7"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"delayOnRouteUpdate":400,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../plugins/gatsby-platform-module/gatsby-browser.tsx'),
      options: {"plugins":[],"protectedRoutePrefix":"/course","includeProtectedFolder":["web3-rollups","ai-infrastructure"],"protectedPagesPath":"src/protected/pages","protectedLayoutsPath":"src/protected/layout","aclConfig":{"subscriptionPaths":{"web3-1":["/course/web3-rollups/"],"ai-1":["/course/ai-infrastructure/"]}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

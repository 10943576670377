import React from "react";
import { GatsbyBrowser } from "gatsby";
import AuthProvider from "./src/components/AuthProvider";
import { OptionsContext } from "./src/utils/contexts";
import { CustomPluginOptions } from "./src/gatsby/pluginOptions";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }, pluginOptions: CustomPluginOptions) => (
    <OptionsContext.Provider value={pluginOptions}>
        <AuthProvider>
            { element }
        </AuthProvider>
    </OptionsContext.Provider>
);

import { User as FirebaseUser } from 'firebase/auth';
import UserAuth from '../models/userAuth';
import FirestoreGateway from '../gateways/firestoreGateway';

export const isBrowser = () => typeof window !== 'undefined';

export const isTest = () => process.env.NODE_ENV === 'test';

export const getUserSubscriptionNames = async (
  firestoreGateway: FirestoreGateway,
  userUid: string
): Promise<string[]> => {
  const userSubscriptionData = await firestoreGateway.showSubscription(userUid);
  const userSubscriptions = userSubscriptionData
    .filter((userSubscription) => userSubscription.subscription?.name)
    .map((userSubscription) => userSubscription.subscription!.name);
  return userSubscriptions;
};

export const mapUserAuth = async (
  user: FirebaseUser | null,
  firestoreGateway: FirestoreGateway
) => {
  let mappedUser = null;

  if (user) {
    const userSubscriptionNames = await getUserSubscriptionNames(
      firestoreGateway,
      user.uid
    );
    mappedUser = new UserAuth(user.uid, user.email, userSubscriptionNames);
  }

  return mappedUser;
};

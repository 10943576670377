import {
  Firestore,
  doc,
  setDoc,
  DocumentReference,
  getDoc,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  query,
  collection,
  where,
  getDocs,
} from 'firebase/firestore';
import User from '../models/user';
import { getFirestoreService } from '../providers/firebaseProvider';
import { INTERNAL_ERROR, USER_ERROR_MESSAGE_MAP } from '../utils/properties';
import Subscription from '../models/subscription';
import UserSubscription from '../models/userSubscription';

const firestoreUserConverter: FirestoreDataConverter<User> = {
  toFirestore: (user: User) => {
    const data: DocumentData = {
      email: user.email,
    };
    if (user.firstName) data.firstName = user.firstName;
    if (user.lastName) data.lastName = user.lastName;
    if (user.deactivatedAt) data.deactivatedAt = user.deactivatedAt.getTime();

    return data;
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ) => {
    const data = snapshot.data(options);
    const deactivatedAt = data.deactivatedAt
      ? new Date(data.deactivatedAt)
      : null;
    return new User(
      snapshot.id,
      data.email,
      data.firstName,
      data.lastName,
      deactivatedAt
    );
  },
};

const firestoreSubscriptionConverter: FirestoreDataConverter<Subscription> = {
  toFirestore: (subscription: Subscription) => {
    return subscription;
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ) => {
    const data = snapshot.data(options);
    return new Subscription(snapshot.id, data.name);
  },
};

const firestoreUserSubscriptionConverter: FirestoreDataConverter<UserSubscription> =
  {
    toFirestore: (subscription: UserSubscription) => {
      return subscription;
    },
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options?: SnapshotOptions
    ) => {
      const data = snapshot.data(options);
      return new UserSubscription(
        snapshot.id,
        data.userId,
        data.subscription,
        undefined
      );
    },
  };

const firestoreErrorMapper = (error: any) => {
  const message =
    USER_ERROR_MESSAGE_MAP[error.code || INTERNAL_ERROR] ||
    USER_ERROR_MESSAGE_MAP[INTERNAL_ERROR];
  return new Error(message);
};

export interface UserGatewayInterface {
  create(user: User): Promise<void>;
  update(user: User): Promise<void>;
  show(uid: string): Promise<User | null>;
  showSubscription(userUid: string): Promise<UserSubscription[]>;
}

export default class FirestoreGateway implements UserGatewayInterface {
  create(user: User): Promise<void> {
    console.log('[FirestoreGateway] create user: ', user.uid);

    return this._setUserDoc(user);
  }

  update(user: User): Promise<void> {
    console.log('[FirestoreGateway] update user: ', user.uid);

    return this._setUserDoc(user);
  }

  async show(uid: string): Promise<User | null> {
    console.log('[FirestoreGateway] retrieve user');

    const db = getFirestoreService();
    const userSnap = await getDoc(this._getUserRef(db, uid));
    return userSnap.exists() ? userSnap.data() : null;
  }

  async showSubscription(userUid: string): Promise<UserSubscription[]> {
    console.log('[FirestoreGateway] retrieve userSubscriptions');

    const db = getFirestoreService();
    const userSubscriptionCollection = collection(db, 'user_subscriptions');
    const userSubscriptionsQuery = query(
      userSubscriptionCollection,
      where('userId', '==', userUid)
    ).withConverter(firestoreUserSubscriptionConverter);
    const userSubscriptionDocs = await getDocs(userSubscriptionsQuery);
    const userSubscriptions = userSubscriptionDocs.docs.map((doc) =>
      doc.data()
    );

    const userSubscriptionsJoined = (
      await Promise.all(
        userSubscriptions.map((us) => {
          return getDoc(
            us.subscriptionRef.withConverter(firestoreSubscriptionConverter)
          );
        })
      )
    )
      .map((subscription) => subscription.data())
      .map(
        (subscription, i) =>
          new UserSubscription(
            userSubscriptions[i].uid,
            userSubscriptions[i].userId,
            userSubscriptions[i].subscriptionRef,
            subscription
          )
      );

    return userSubscriptionsJoined;
  }

  _setUserDoc(user: User): Promise<void> {
    const db = getFirestoreService();
    const userRef = this._getUserRef(db, user.uid);
    return setDoc(userRef, user);
  }

  _getUserRef(db: Firestore, uid: string): DocumentReference<User> {
    return doc(db, 'users', uid).withConverter(firestoreUserConverter);
  }

  _errorHandler(error: any) {
    console.error('[FirestoreGatewayError] ', JSON.stringify(error));
    throw firestoreErrorMapper(error);
  }
}

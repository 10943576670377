import React from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirebaseAuthService } from '../providers/firebaseProvider';
import { AuthContext } from '../utils/contexts';
import UserAuth from '../models/userAuth';
import { mapUserAuth } from '../utils/helpers';
import FirestoreGateway from '../gateways/firestoreGateway';

const AuthProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = React.useState<UserAuth | null>(null);
  const firestoreGateway = React.useRef(new FirestoreGateway());

  React.useEffect(() => {
    const auth = getFirebaseAuthService();

    onAuthStateChanged(auth, async (user) => {
      const userData = await mapUserAuth(user, firestoreGateway.current);
      setCurrentUser(userData);
    });
  }, []);

  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
